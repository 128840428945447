/**
 * @license
/* Copyright (C) 2024 $PEAKY - All Rights Reserved
 * You may not use, distribute and modify this code under the
 * terms of the US Millennium Copyright Act.
 * 
 * The Digital Millennium Copyright Act (DMCA) is a federal law that was passed by Congress in 1998 
 * to update US copyright law and address the relationship between copyright and the internet.
 * The DMCA protects copyright holders from online theft by covering copyrighted materials such as music,
 * movies, and text. It also limits the liability of online service providers for copyright infringement by their users.
 */

import {
  Raycaster,
  Clock,
  Scene,
  EquirectangularReflectionMapping,
  SRGBColorSpace,
  ACESFilmicToneMapping,
  PerspectiveCamera,
  AnimationMixer,
  AnimationClip,
  Vector2,
  PlaneGeometry,
  Mesh,
  LoopOnce,
  MathUtils,
  WebGLRenderer,
  ShadowMaterial,
  MeshBasicMaterial,
  Object3D,
  SpotLight,
  Group,
  AudioListener,
  AudioLoader,
  Audio,
  LoadingManager,
} from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import MouseFollower from "mouse-follower";
import gsap from "gsap";
import { UAParser } from "ua-parser-js";

const { device } = UAParser(
  "Mozilla/5.0 (X11; U; Linux armv7l; en-GB; rv:1.9.2a1pre) Gecko/20090928 Firefox/3.5 Maemo Browser 1.4.1.22 RX-51 N900"
);

console.log(device === "mobile" || device === "tablet");

MouseFollower.registerGSAP(gsap);

// Set the main variables
let scene;
let renderer;
let texture;
let camera;
let model;
let neck;
let waist;
let possibleAnims;
let mixer;
let idle;
let eyes;
let walk;
let limitations;
let limitations2;
let yellingmouthanim;
let fuckingteethanim;
let defeatmouthanim;
let defeatteethanim;
let textVar;
let currentlyAnimating = false;
let themesong;
let walks;
let limitsound;
let fuckingcansound;
let defeatsound;
let controls;
let smoothReset;
let clickMesh;
let clicker;

const cursor = new MouseFollower({
  className: "mf-cursor",
  ease: "expo.out",
  stickDelta: "0",
});

const clock = new Clock();
const raycaster = new Raycaster();
const Mgroup = new Group();
const MODEL_PATH = "pic4-v11.glb";
const canvas = document.querySelector("#c");
const body = document.querySelector("body");
const name = document.querySelector(".name");
const containerload = document.getElementById("container-load");
const progresspercent = document.getElementById("progresspercent");
const startButton = document.querySelector(".startButton");
const introtext = document.querySelector(".introtext");
const introimg = document.querySelector(".introimg");
const buttons = document.querySelector(".buttons");

const manager = new LoadingManager();

manager.onLoad = function () {
  startButton.style.opacity = 1;
  introtext.style.opacity = 1;
  introimg.style.opacity = 1;
  progresspercent.style.opacity = 0;
  containerload.style.pointerEvents = "auto";
};

manager.onProgress = function (url, itemsLoaded, itemsTotal) {
  progresspercent.innerHTML =
    "Loading " + Math.floor((itemsLoaded / itemsTotal) * 100) + "%";
};

manager.onError = function (url) {
  console.log("There was an error loading " + url);
};
startButton.addEventListener("click", begin);
startButton.addEventListener("touchend", begin, { passive: false });
function begin() {
  camera.position.set(0, 0, 4.2);
  controls.update();
  containerload.style.opacity = 0;
  containerload.style.zIndex = -10;
  containerload.style.pointerEvents = "none";
  name.style.zIndex = "0";
  if (matchMedia("(max-width: 600px)").matches) {
    name.style.width = "40%";
  } else {
    name.style.width = "20%";
  }
  playModifierAnimation(idle, 0.25, walk, 0.25);
  buttons.style.opacity = 1;
  buttons.style.marginBottom = "20px";
  let tlp = gsap.timeline({});

  if (matchMedia("(pointer:coarse)").matches) {
    tlp.fromTo(
      Mgroup.position,
      { z: -3 },
      {
        z: 0,
        duration: 2,
        ease: "power1",
        onStart: () => {
          walks.play();
        },
      }
    );
  } else {
    tlp.fromTo(
      Mgroup.position,
      { z: -3 },
      {
        z: 0,
        duration: 2,
        ease: "power1",
        onStart: () => {
          walks.play();
        },
      }
    );
  }
}

// starting new drag with OrbitCintrols -- recover the min.max values
function onStart() {
  controls.minAzimuthAngle = -Infinity;
  controls.maxAzimuthAngle = Infinity;
  controls.minPolarAngle = Math.PI / 2;
  controls.maxPolarAngle = Math.PI / 2;
  smoothReset = false;
}

// enging drag with OrbitControls -- activate smooth reset
function onEnd() {
  smoothReset = true;
}

// function to smooth reset the OrbitControl camera's angles
function doSmoothReset() {
  // get current angles
  var alpha = controls.getAzimuthalAngle(),
    beta = controls.getPolarAngle() - Math.PI / 2;

  // if they are close to the reset values, just set these values
  if (Math.abs(alpha) < 0.001) alpha = 0;
  if (Math.abs(beta) < 0.001) beta = 0;

  // smooth change using manual lerp
  controls.minAzimuthAngle = 0.95 * alpha;
  controls.maxAzimuthAngle = controls.minAzimuthAngle;

  controls.minPolarAngle = Math.PI / 2 + 0.95 * beta;
  controls.maxPolarAngle = controls.minPolarAngle;

  // if the reset values are reached, exit smooth reset
  if (alpha == 0 && beta == 0) onStart();
}

init();
function init() {
  // Init the scene
  scene = new Scene();

  new RGBELoader(manager)
    .setPath("")
    .load("old_hall_1k.hdr", function (texture) {
      texture.mapping = EquirectangularReflectionMapping;
      scene.environment = texture;
    });

  // Init the renderer
  renderer = new WebGLRenderer({
    canvas,
    antialias: true,
    alpha: true,
  });
  renderer.shadowMap.enabled = true;
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.outputColorSpace = SRGBColorSpace;
  renderer.toneMapping = ACESFilmicToneMapping;
  renderer.powerPreference = "high-performance";
  renderer.toneMappingExposure = 0.2;
  renderer.setClearColor(0x000000, 0);
  document.body.appendChild(renderer.domElement);

  // Add a camera
  camera = new PerspectiveCamera(
    35,
    window.innerWidth / window.innerHeight,
    0.1,
    40
  );

  camera.position.set(0, 0, 4.2);
  camera.lookAt(scene.position);
  controls = new OrbitControls(camera, renderer.domElement);
  controls.enablePan = false;

  controls.enableDamping = true;
  controls.enableZoom = false;
  smoothReset = false;

  controls.addEventListener("start", onStart);
  controls.addEventListener("end", onEnd);

  const loader = new GLTFLoader(manager);
  loader.setMeshoptDecoder(MeshoptDecoder);
  loader.load(MODEL_PATH, function (gltf) {
    model = gltf.scene;
    const fileAnimations = gltf.animations;
    model.traverse((o) => {
      if (o.isMesh) {
        o.frustumCulled = false;
        o.castShadow = true;
        o.receiveShadow = true;
        o.envMap = texture;
      }
      // Reference the neck and waist bones
      if (o.isBone && o.name === "mixamorigNeck") {
        neck = o;
      }
      if (o.isBone && o.name === "mixamorigSpine") {
        waist = o;
      }
    });

    model.scale.set(1, 1, 1);
    model.position.y = -1;
    Mgroup.position.z = -4;
    Mgroup.add(model);

    mixer = new AnimationMixer(model);
    const clips = fileAnimations.filter(
      (val) =>
        val.name !== "idle" &&
        val.name !== "walk" &&
        val.name !== "limitations" &&
        val.name !== "limitations2" &&
        val.name !== "eyes" &&
        val.name !== "yellingmouth" &&
        val.name !== "fuckingteet" &&
        val.name !== "yellingvoice" &&
        val.name !== "defeatmouth" &&
        val.name !== "defeatteeth"
    );

    possibleAnims = clips.map((val) => {
      let clip = AnimationClip.findByName(clips, val.name);
      clip.tracks.splice(3, 3);
      clip.tracks.splice(9, 3);
      clip = mixer.clipAction(clip);
      return clip;
    });

    const idleAnim = AnimationClip.findByName(fileAnimations, "idle");
    idleAnim.tracks.splice(3, 3);
    idleAnim.tracks.splice(9, 3);
    idle = mixer.clipAction(idleAnim);
    idle.play();

    const eyesAnim = AnimationClip.findByName(fileAnimations, "eyes");
    eyesAnim.tracks.splice(3, 3);
    eyesAnim.tracks.splice(9, 3);
    eyes = mixer.clipAction(eyesAnim);
    eyes.play();

    const walkAnim = AnimationClip.findByName(fileAnimations, "walk");
    walkAnim.tracks.splice(3, 3);
    walkAnim.tracks.splice(9, 3);
    walkAnim.duration /= 1.8;
    walk = mixer.clipAction(walkAnim);

    const limitationsmouth = AnimationClip.findByName(
      fileAnimations,
      "limitations"
    );
    limitations = mixer.clipAction(limitationsmouth);

    const limitationsmouth2 = AnimationClip.findByName(
      fileAnimations,
      "limitations2"
    );
    limitations2 = mixer.clipAction(limitationsmouth2);

    const yellingmouth = AnimationClip.findByName(
      fileAnimations,
      "yellingmouth"
    );
    yellingmouthanim = mixer.clipAction(yellingmouth);

    const fuckingteeth = AnimationClip.findByName(
      fileAnimations,
      "fuckingteet"
    );
    fuckingteethanim = mixer.clipAction(fuckingteeth);

    const defeatteeth = AnimationClip.findByName(
      fileAnimations,
      "defeatteeth"
    );
    defeatteethanim = mixer.clipAction(defeatteeth);

    const defeatmouth = AnimationClip.findByName(
      fileAnimations,
      "defeatmouth"
    );
    defeatmouthanim = mixer.clipAction(defeatmouth);
  });

  const targetObject = new Object3D();
  targetObject.position.set(0, 2, 0);
  scene.add(targetObject);

  var spotlight3 = new SpotLight(0xffffff, 20);
  spotlight3.position.set(0, 2, 0.5);
  spotlight3.angle = 90;
  spotlight3.castShadow = true;
  spotlight3.bias = 0.0001;
  spotlight3.shadow.mapSize.width = 1024;
  spotlight3.shadow.mapSize.height = 1024;

  spotlight3.shadow.camera.near = 0.1;
  spotlight3.shadow.camera.far = 50;
  spotlight3.shadow.camera.fov = 50;

  Mgroup.add(spotlight3);

  var spotlight = new SpotLight(0xffffff, 250);
  spotlight.position.set(1, 1, 2);
  spotlight.angle = Math.PI / 1;
  spotlight.penumbra = 0.5;
  spotlight.decay = 4;

  spotlight.target = targetObject;
  Mgroup.add(spotlight);

  // Shadow Catcher
  const shadowGeometry = new PlaneGeometry(16, 16, 1, 1);

  const shadowMaterial2 = new ShadowMaterial({
    opacity: 0.4,
  });

  const shadowCatcher2 = new Mesh(shadowGeometry, shadowMaterial2);
  shadowCatcher2.rotation.x = -0.5 * Math.PI;
  shadowCatcher2.receiveShadow = true;
  shadowCatcher2.position.y = -1;
  scene.add(shadowCatcher2);

  // Add the Clickable Mesh to scene
  const ClickGeometry = new PlaneGeometry(0.7, 1.95, 1, 1);
  const ClickMaterial = new MeshBasicMaterial({
    color: 0x000000,
    opacity: 0,
    transparent: true,
  });
  clickMesh = new Mesh(ClickGeometry, ClickMaterial);
  clickMesh.position.z = 0.3;
  clickMesh.position.y = -0.2;
  clickMesh.name = "clickmesh";
  scene.add(Mgroup);

  const listener = new AudioListener();
  camera.add(listener);

  // create a global audio source
  themesong = new Audio(listener);
  walks = new Audio(listener);
  limitsound = new Audio(listener);
  fuckingcansound = new Audio(listener);
  defeatsound = new Audio(listener);

  // load a sound and set it as the Audio object's buffer
  const audioLoader = new AudioLoader(manager);
  audioLoader.load("./sounds/themesong.mp3", function (buffer) {
    themesong.setBuffer(buffer);
    themesong.setLoop(true);
    themesong.setVolume(0.4);
  });
  const audioLoader2 = new AudioLoader(manager);
  audioLoader2.load("./sounds/walk.mp3", function (buffer) {
    walks.setBuffer(buffer);
    walks.setLoop(false);
    walks.setVolume(1);
    walks.offset = 0;
  });

  walks.onEnded = () => {
    themesong.play();
    document.addEventListener("mousemove", look);
    Mgroup.add(clickMesh);
  };

  const audioLoader3 = new AudioLoader(manager);
  audioLoader3.load("./sounds/limit.mp3", function (buffer) {
    limitsound.setBuffer(buffer);
    limitsound.setLoop(false);
    limitsound.setVolume(1.2);
  });

  const audioLoader4 = new AudioLoader(manager);
  audioLoader4.load("./sounds/fuckingcan.mp3", function (buffer) {
    fuckingcansound.setBuffer(buffer);
    fuckingcansound.setLoop(false);
    fuckingcansound.setVolume(0.4);
  });

  const audioLoader6 = new AudioLoader(manager);
  audioLoader6.load("./sounds/continue.mp3", function (buffer) {
    defeatsound.setBuffer(buffer);
    defeatsound.setLoop(false);
    defeatsound.setVolume(0.4);
  });
} // end of the init function

const pointer = new Vector2();
function onPointerMove(e) {
  pointer.x = (e.clientX / window.innerWidth) * 2 - 1;
  pointer.y = -(e.clientY / window.innerHeight) * 2 + 1;
}

function render() {
  requestAnimationFrame(render);
  if (mixer) {
    mixer.update(clock.getDelta());
  }

  if (resizeRendererToDisplaySize(renderer)) {
    const canvas = renderer.domElement;
    camera.aspect = canvas.clientWidth / canvas.clientHeight;
    camera.updateProjectionMatrix();
  }

  raycaster.setFromCamera(pointer, camera);
  if (smoothReset) doSmoothReset();
  controls.update();
  renderer.render(scene, camera);
}
window.addEventListener("pointermove", onPointerMove);
render();

function resizeRendererToDisplaySize(renderer) {
  const canvas = renderer.domElement;
  const width = window.innerWidth;
  const height = window.innerHeight;
  const canvasPixelWidth = canvas.width / window.devicePixelRatio;
  const canvasPixelHeight = canvas.height / window.devicePixelRatio;
  const needResize =
    canvasPixelWidth !== width || canvasPixelHeight !== height;
  if (needResize) {
    renderer.setSize(width, height, false);
  }
  return needResize;
}

clicker = document.querySelector("#clickmesh");
function raycast(e, touch = false) {
  const mouse = {};
  if (touch) {
    mouse.x =
      2 * (e.changedTouches[0].clientX / window.innerWidth) - 1;
    mouse.y =
      1 - 2 * (e.changedTouches[0].clientY / window.innerHeight);
  } else {
    mouse.x = 2 * (e.clientX / window.innerWidth) - 1;
    mouse.y = 1 - 2 * (e.clientY / window.innerHeight);
  }
  // update the picking ray with the camera and mouse position
  raycaster.setFromCamera(mouse, camera);

  // calculate objects intersecting the picking ray
  const intersects = raycaster.intersectObjects(scene.children, true);

  if (intersects[0]) {
    const object = intersects[0].object;
    if (object.name === "clickmesh") {
      if (!currentlyAnimating) {
        currentlyAnimating = true;
        playOnClick();
      }
    }
  }
}

clicker.addEventListener("click", (e) => raycast(e));
canvas.addEventListener("touchstart", (e) => raycast(e, true));

// Get a random animation, play it, and drive the progress bar
let currentAnimIndex = 0; // Variable to store the current animation index

function playOnClick() {
  // Play the current animation
  let anim = currentAnimIndex;

  // Update the index to the next animation
  currentAnimIndex = (currentAnimIndex + 1) % possibleAnims.length;

  // Play the selected animation
  playModifierAnimation(idle, 0.5, possibleAnims[anim], 0.5);
  const time = possibleAnims[anim]._clip.duration - 0.5;

  // Logic for specific animations
  if (possibleAnims[anim]._clip.name === "limit") {
    limitsound.play();
    limitations.play();
    limitations2.play();
    textVar = "WAIT";
    limitsound.source.onended = function () {
      limitsound.stop();
      limitations.stop();
      limitations2.play();
    };
  } else if (possibleAnims[anim]._clip.name === "yelling") {
    fuckingcansound.play();
    yellingmouthanim.play();
    fuckingteethanim.play();
    textVar = "WAIT";
    fuckingcansound.source.onended = function () {
      yellingmouthanim.stop();
      fuckingteethanim.stop();
      fuckingcansound.stop();
    };
  } else if (possibleAnims[anim]._clip.name === "defeat") {
    defeatsound.play();
    defeatmouthanim.play();
    defeatteethanim.play();
    textVar = "WAIT";
    defeatsound.source.onended = function () {
      defeatmouthanim.stop();
      defeatteethanim.stop();
      defeatsound.stop();
    };
  }

  cursor.setText(textVar);
  document.body.style.setProperty("--text", `"${textVar}"`);
  setTimeout(() => {
    limitsound.stop();
    limitations.stop();
    limitations2.stop();
    yellingmouthanim.stop();
    fuckingteethanim.stop();
    textVar = "CLICK ME";
    cursor.setText(textVar);
    cursor.removeText();
    document.body.style.setProperty("--text", `"${textVar}"`);
  }, time * 1000);
}

function playModifierAnimation(from, fSpeed, to, tSpeed) {
  to.setLoop(LoopOnce);
  to.reset();
  to.play();
  from.crossFadeTo(to, fSpeed, true);
  setTimeout(function () {
    from.enabled = true;
    to.crossFadeTo(from, tSpeed, true);
    currentlyAnimating = false;
  }, to._clip.duration * 1000 - (tSpeed + fSpeed) * 1000);
}

function look(e) {
  const mousecoords = getMousePos(e);
  if (neck && waist) {
    moveJoint(mousecoords, neck, 50);
    moveJoint(mousecoords, waist, 30);
  }
}

function getMousePos(e) {
  return { x: e.clientX, y: e.clientY };
}

function moveJoint(mouse, joint, degreeLimit) {
  const degrees = getMouseDegrees(mouse.x, mouse.y, degreeLimit);
  joint.rotation.y = MathUtils.degToRad(degrees.x);
  joint.rotation.x = MathUtils.degToRad(degrees.y);
}

function getMouseDegrees(x, y, degreeLimit) {
  let dx = 0;
  let dy = 0;
  let xdiff;
  let xPercentage;
  let ydiff;
  let yPercentage;

  const w = { x: window.innerWidth, y: window.innerHeight * 0.4 };

  // Left (Rotates neck left between 0 and -degreeLimit)
  // 1. If cursor is in the left half of screen
  if (x <= w.x / 2) {
    // 2. Get the difference between middle of screen and cursor position
    xdiff = w.x / 2 - x;
    // 3. Find the percentage of that difference (percentage toward edge of screen)
    xPercentage = (xdiff / (w.x / 2)) * 100;
    // 4. Convert that to a percentage of the maximum rotation we allow for the neck
    dx = ((degreeLimit * xPercentage) / 100) * -1;
  }

  // Right (Rotates neck right between 0 and degreeLimit)
  if (x >= w.x / 2) {
    xdiff = x - w.x / 2;
    xPercentage = (xdiff / (w.x / 2)) * 100;
    dx = (degreeLimit * xPercentage) / 100;
  }
  // Up (Rotates neck up between 0 and -degreeLimit)
  if (y <= w.y / 2) {
    ydiff = w.y / 2 - y;
    yPercentage = (ydiff / (w.y / 2)) * 100;
    // Note that I cut degreeLimit in half when she looks up
    dy = ((degreeLimit * 0.5 * yPercentage) / 100) * -1;
  }
  // Down (Rotates neck down between 0 and degreeLimit)
  if (y >= w.y / 2) {
    ydiff = y - w.y / 2;
    yPercentage = (ydiff / (w.y / 2)) * 100;
    dy = ((degreeLimit / 5.5) * yPercentage) / 100;
  }
  return { x: dx, y: dy };
}

const cacopy = document.getElementById("ca");
const dialogabout = document.querySelector("#dialogabout");
const dialogcontract = document.querySelector("#dialogca");
const openAbout = document.querySelector("#about");
const openContract = document.querySelector("#contract");

clicker.addEventListener("mouseenter", () => {
  if (!currentlyAnimating) {
    cursor.setText("CLICK ME");
    body.style.cursor = "none";
  } else {
    cursor.setText("WAIT");
    body.style.cursor = "none";
  }
});

clicker.addEventListener("mouseleave", () => {
  cursor.removeText();
  body.style.cursor = "auto";
});

openAbout.addEventListener("mouseenter", () => {
  cursor.setText("ABOUT THE PROJECT");
  body.style.cursor = "none";
});

openAbout.addEventListener("mouseleave", () => {
  cursor.removeText();
  body.style.cursor = "auto";
});

openAbout.addEventListener("click", () => {
  dialogabout.showModal();
});

dialogabout.addEventListener("click", ({ target: dialog }) => {
  if (dialog.nodeName === "DIALOG") {
    dialog.close("dismiss");
  }
});

openContract.addEventListener("mouseenter", () => {
  cursor.setText("CONTRACT");
  body.style.cursor = "none";
});

openContract.addEventListener("mouseleave", () => {
  cursor.removeText();
  body.style.cursor = "auto";
});

openContract.addEventListener("click", () => {
  dialogcontract.showModal();
});

dialogcontract.addEventListener("click", ({ target: dialog }) => {
  if (dialog.nodeName === "DIALOG") {
    dialog.close("dismiss");
  }
});

cacopy.addEventListener("click", () =>
  writeClipboardText("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx")
);

async function writeClipboardText(
  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
) {
  try {
    await navigator.clipboard.writeText(
      xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    );
  } catch (error) {
    console.error(error.message);
  }
}
